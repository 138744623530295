import { NgClass } from '@angular/common';
import { Component, computed, effect, input, output, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResizedDirective, ResizedEvent } from '@assethub/shared/directives/resized';
import {
  AssetLocation,
  AssetLocationDetails,
  AssetTypeMapping,
  GetAssetResponse,
  GpsPosition,
  MapMarkers,
} from '@assethub/shared/models';
import { GeoLocationService } from '@assethub/shared/services';
import { Logger } from '@assethub/shared/utils';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AssetHeremapComponent } from '../asset-heremap/asset-heremap.component';
import { TextFieldComponent } from '../text-field/text-field.component';

interface Country {
  code: string;
  label: string;
}

@Component({
  selector: 'app-asset-location-edit',
  templateUrl: './asset-location-edit.component.html',
  styleUrls: ['./asset-location-edit.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    ButtonModule,
    TranslateModule,
    NgClass,
    TextFieldComponent,
    AssetHeremapComponent,
    ResizedDirective,
    RadioButtonModule,
  ],
})
export class AssetLocationEditComponent {
  readonly asset = input<GetAssetResponse>();
  readonly assetType = input<string>('');
  readonly filteredCountries = signal<Country[]>([]);

  readonly updateMapEnabled = computed<boolean>(() => this.computeUpdateMapEnabled());
  readonly mapMarkers = computed(() => this.computeMarkers());
  readonly narrow = signal<boolean>(false);

  // data visible / editable by user
  readonly edit = {
    useInherited: signal<boolean>(true),
    address1: signal<string>(''),
    address2: signal<string>(''),
    zipCode: signal<string>(''),
    city: signal<string>(''),
    country: signal<Country | undefined>(undefined),
    position: signal<GpsPosition | undefined>(undefined),
  };

  // output edited location, undefined if unchanged (or empty), null to delete
  readonly editLocation = output<AssetLocation | undefined | null>();

  private logger = new Logger('AssetLocationEditComponent');
  private readonly supportedCountries = computed(() => this.computeSupportedCountries());
  private readonly currentLocation = computed(() => this.computeCurrentLocation());
  private readonly resultLocation = computed(() => this.computeResultLocation());

  constructor(
    private geoLocationService: GeoLocationService,
    private translateService: TranslateService,
  ) {
    effect(
      () => {
        const asset = this.asset();
        this.edit.useInherited.set(asset?.location?.inherited ?? true);
        this.setFormLocation(asset?.location);
      },
      { allowSignalWrites: true },
    );

    effect(() => {
      this.editLocation.emit(this.resultLocation());
    });
  }

  toggleUseInherited(useInherited: boolean) {
    const asset = this.asset();
    if (asset && asset.location && asset.location.inherited === useInherited) {
      this.setFormLocation(asset.location);
    } else {
      this.setFormLocation();
    }
  }

  onResized(event: ResizedEvent) {
    this.narrow.set(event.newRect.width < 600);
  }

  updateMap() {
    this.geoLocationService.findByAddress(this.currentLocation()).subscribe({
      next: location => {
        this.edit.position.set(location);
      },
    });
  }

  filterCountry(event: AutoCompleteCompleteEvent) {
    const filtered: Country[] = [];
    const query = event.query.toLowerCase();
    const supportedCountries = this.supportedCountries();
    for (const country of supportedCountries) {
      if (country.label.toLowerCase().startsWith(query)) {
        filtered.push(country);
      }
    }
    this.filteredCountries.set(filtered);
  }

  private isEmptyAddress(a: AssetLocation) {
    return !a.address1 && !a.address2 && !a.zipCode && !a.city && !a.country;
  }

  private equalAddresses(a1: AssetLocation, a2: AssetLocation) {
    return (
      a1.address1 === a2.address1 &&
      a1.address2 === a2.address2 &&
      a1.zipCode === a2.zipCode &&
      a1.city === a2.city &&
      a1.country === a2.country &&
      a1.latitude === a2.latitude &&
      a1.longitude === a2.longitude
    );
  }

  private computeSupportedCountries() {
    const value: { code: string; label: string }[] = this.translateService.instant('countries');
    return value.sort((a, b) => a.label.localeCompare(b.label));
  }

  private computeResultLocation(): AssetLocation | null | undefined {
    const asset = this.asset();
    const assetLocation = asset?.location;
    const currentLocation = this.currentLocation();
    const newUseInherited = this.edit.useInherited();

    // new own location if not to use inherited and not all fields empty
    const newOwnLocation =
      !newUseInherited && !this.isEmptyAddress(currentLocation) ? currentLocation : undefined;

    // old own location (if not inherited)
    const oldOwnLocation = assetLocation?.inherited === false ? assetLocation : undefined;

    // now use inherited address
    if (newUseInherited) {
      if (oldOwnLocation) {
        // use inherited address and previously had own address -> null (delete address)
        return null;
      }
      // use inherited address and previously had no own address -> undefined (no own address)
      return undefined;
    }

    // previously had own address
    if (oldOwnLocation) {
      if (!newOwnLocation) {
        // have old own address and new own address is empty -> null (delete address)
        return null;
      }
      if (this.equalAddresses(oldOwnLocation, newOwnLocation)) {
        // old own address and new own address are the same -> undefined (no change)
        return undefined;
      }
    }

    if (!newOwnLocation) {
      // own address is empty -> undefined (do not set address)
      return undefined;
    }

    // new own address
    return newOwnLocation;
  }

  private computeUpdateMapEnabled(): boolean {
    const currentLocation = this.currentLocation();
    return !!currentLocation.city && !!currentLocation.zipCode && !!currentLocation.country;
  }

  private computeMarkers(): MapMarkers {
    const mapLocation = this.edit.position();
    if (!mapLocation || !mapLocation.latitude || !mapLocation.longitude) {
      return [];
    }

    const asset = this.asset();
    const assetType = this.assetType();
    return [
      {
        latitude: mapLocation.latitude,
        longitude: mapLocation.longitude,
        assetUuid: asset?.uuid || '',
        typeId: asset?.typeId ?? parseInt(assetType, 10) ?? AssetTypeMapping.LOCATION,
        name: asset?.customName,
        profilePicture: asset?.profilePicture?.small,
        productPictureUrl: asset?.details?.productPictureUrl,
        productName: asset?.details?.productName,
      },
    ];
  }

  private getCountry(value: string | undefined): Country | undefined {
    if (!value) {
      return;
    }
    const supportedCountries = this.supportedCountries();
    const country = supportedCountries.find(x => x.code === value);
    if (country) {
      return country;
    }
    return supportedCountries.find(x => x.label === value);
  }

  private computeCurrentLocation(): AssetLocation {
    const edit = this.edit;
    const position = this.edit.position();
    return {
      address1: edit.address1(),
      address2: edit.address2(),
      zipCode: edit.zipCode(),
      city: edit.city(),
      country: edit.country()?.code || '',
      latitude: position?.latitude || null,
      longitude: position?.longitude || null,
    };
  }

  private setFormLocation(location: AssetLocationDetails | undefined = undefined) {
    const edit = this.edit;
    edit.address1.set(location?.address1 || '');
    edit.address2.set(location?.address2 || '');
    edit.zipCode.set(location?.zipCode || '');
    edit.city.set(location?.city || '');
    edit.country.set(this.getCountry(location?.country));
    if (location && location.latitude !== null && location.longitude !== null) {
      edit.position.set(location);
    } else {
      edit.position.set(undefined);
    }
  }
}
