import { Component, Optional, SkipSelf } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  AssetItem,
  AssetTypeMapping,
  assetTypeMappingToString,
  GetAssetResponse,
} from '@assethub/shared/models';
import { ActivatedRoute, Router } from '@angular/router';
import { BasicMenuComponent } from '../menu/basic-menu.component';
import { BasicMenuItemAssetComponent } from './basic-menu-item-asset.component';
import { LanguageService, LicensingService, TreeService } from '../../services';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';
import { AssetLocationMapComponent } from '../asset-location-map/asset-location-map.component';

@Component({
  selector: 'app-menu-item-asset-locations',
  templateUrl: './basic-menu-item-asset.component.html',
  styleUrls: ['./basic-menu-item-asset.component.scss'],
  standalone: true,
  imports: [MenuItemComponent],
})
export class MenuItemAssetLocationsComponent extends BasicMenuItemAssetComponent {
  constructor(
    sanitizer: DomSanitizer,
    @SkipSelf() @Optional() parentMenu: BasicMenuComponent | null,
    languageService: LanguageService,
    router: Router,
    activatedRoute: ActivatedRoute,
    treeService: TreeService,
    licensingService: LicensingService,
  ) {
    super(
      sanitizer,
      parentMenu,
      languageService,
      router,
      activatedRoute,
      treeService,
      licensingService,
    );
    this.setup('map', 'assetDetails.menu.show-locations');
  }

  private static isTypeShip(asset?: GetAssetResponse | AssetItem) {
    if (!asset) {
      return false;
    }
    if (asset instanceof AssetItem) {
      return asset.type.toLowerCase() === assetTypeMappingToString[AssetTypeMapping.SHIP];
    }
    return asset.typeId === AssetTypeMapping.SHIP;
  }

  static override isViableOption(asset?: GetAssetResponse | AssetItem): boolean {
    if (!asset) {
      return false;
    }
    return !MenuItemAssetLocationsComponent.isTypeShip(asset);
  }

  protected override getReason(): string {
    if (!this.asset) {
      return '';
    }
    if (MenuItemAssetLocationsComponent.isTypeShip(this.asset)) {
      return 'locationRequiresNotShip';
    }
    return '';
  }

  override onClick() {
    if (!this.asset || this.disabled) {
      return;
    }
    super.onClick();
    AssetLocationMapComponent.open(this.asset.uuid);
  }
}
