import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AssetLocation, ENVIRONMENT } from '@assethub/shared/models';
import { MessageService } from 'primeng/api';
import { catchError, map, Observable, of } from 'rxjs';
import { GetGeoLocationsResponse, GpsPosition } from '../models/geo-location';
import { Logger } from '../utils';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
  private apiUrl: string = inject(ENVIRONMENT).apiUrl;
  private hereWeGoApiUrl = 'https://geocode.search.hereapi.com/v1/geocode';
  private hereWeGoApiKey = inject(ENVIRONMENT).hereWeGoApiKey;
  private httpClient = inject(HttpClient);
  private logger = new Logger('GeoLocationService');

  constructor(private messageService: MessageService) {}

  public getGeoLocations(assetUuid: string): Observable<GetGeoLocationsResponse> {
    return this.httpClient.post<GetGeoLocationsResponse>(
      this.apiUrl + '/asset/' + assetUuid + '/locations',
      {},
    );
  }

  public findByAddress(address: AssetLocation): Observable<GpsPosition> {
    if (!this.hereWeGoApiUrl) {
      return of({ longitude: null, latitude: null });
    }
    if (!address.city || !address.zipCode || !address.country) {
      if (address.city || address.zipCode || address.country) {
        this.messageService.add({
          severity: 'warn',
          summary: 'geoLocation.toasts.incomplete-address',
          life: 10000,
        });
      }
      return of({ longitude: null, latitude: null });
    }
    const params = {
      apiKey: this.hereWeGoApiKey,
      qq: `city=${address.city};postalCode=${address.zipCode};country=${address.country};street=${address.address1} ${address.address2}`,
      limit: '1',
    };
    return this.httpClient.get<any>(this.hereWeGoApiUrl, { params }).pipe(
      map(res => {
        if (res && res.items && res.items[0]) {
          const latitude = parseFloat(res.items[0].position.lat);
          const longitude = parseFloat(res.items[0].position.lng);
          if (!isNaN(latitude) && !isNaN(longitude)) {
            return {
              latitude,
              longitude,
            };
          }
        }
        this.messageService.add({
          severity: 'warn',
          summary: 'geoLocation.toasts.no-gps-position-found',
          life: 10000,
        });
        return { longitude: null, latitude: null };
      }),
      catchError(err => {
        this.messageService.add({
          severity: 'error',
          summary: 'geoLocation.toasts.query-gps-position-failed',
          life: 10000,
        });
        this.logger.warn(`Failed to query geo location: `, err);
        return of({ latitude: null, longitude: null });
      }),
    );
  }
}
