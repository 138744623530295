import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ResizedEvent } from '@assethub/shared/directives/resized';
import { MultiSelectItem } from '@assethub/shared/models/multiselect';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { IconComponent } from '../icon/icon.component';
import { SharedModule } from 'primeng/api';
import { ResizedDirective } from '../../directives/resized/resized.directive';
import { FormsModule } from '@angular/forms';
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { AssetIconComponent } from '../asset-icon/asset-icon.component';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss'],
  standalone: true,
  imports: [
    MultiSelectModule,
    FormsModule,
    ResizedDirective,
    SharedModule,
    IconComponent,
    ButtonModule,
    TranslateModule,
    AssetIconComponent,
  ],
})
export class MultiselectComponent implements OnChanges, OnInit {
  @Input() options: MultiSelectItem[] = [];
  @Input() selectedOptions: MultiSelectItem[] = [];
  @Input() enableFilterInput = true;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() translateItems = false;

  @Output()
  selectedOptionsChange: EventEmitter<MultiSelectItem[]> = new EventEmitter();

  @ViewChild(MultiSelect)
  multiSelect?: MultiSelect;

  panelStyle: { [k: string]: string | number } = {};
  displayFilterInput = true;
  enableSelectAll = false;
  enableUnselectAll = false;

  readonly maxSelectedLabels = 3;

  private readonly displayFilterMinOptions = 15;

  ngOnInit(): void {
    this.updateFilterInputState();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options?.currentValue || changes.enableFilterInput?.currentValue !== undefined) {
      this.updateFilterInputState();
    }
  }

  updateFilterInputState() {
    this.displayFilterInput =
      this.enableFilterInput && this.options?.length >= this.displayFilterMinOptions;
    this.manageFooterButtons();
  }

  unSelectAll(event: Event) {
    // Prevents from collapsing the multiselect component
    event.preventDefault();
    event.stopPropagation();
    this.selectedOptions = [];
    this.update();
  }

  selectAll() {
    if (this.multiSelect) {
      this.selectedOptions = [
        ...this.multiSelect.visibleOptions(),
      ]; /* Clone instead of setting reference */
      this.update();
    }
  }

  update() {
    this.selectedOptionsChange.emit(this.selectedOptions);
    this.manageFooterButtons();
  }

  private manageFooterButtons() {
    this.enableUnselectAll = this.selectedOptions.length > 0;

    this.enableSelectAll =
      this.options.length > 0 && this.options.length !== this.selectedOptions.length;
  }

  onResized(event: ResizedEvent) {
    this.panelStyle = { 'width.px': event.newRect.width };
  }
}
