import { Component, computed, effect, input, model, signal, untracked } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { MediaVideoComponent } from '../media-video/media-video.component';
import { MediaImageComponent } from '../media-image/media-image.component';
import { MediaCustomImageComponent } from '../media-custom-image/media-custom-image.component';
import { MediaViewerComponent } from '../media-viewer/media-viewer.component';
import { MediaUploadComponent } from '../media-upload/media-upload.component';
import {
  AssetService,
  ImageService,
  MenuService,
  UploadStatus,
  ZOOMED_IMAGE_SIZE,
} from '@assethub/shared/services';
import { IconComponent } from '../icon/icon.component';
import { TemplateDirective } from '../../directives/template.directive';
import { NoElementsPlaceholderComponent } from '../no-elements-placeholder/no-elements-placeholder.component';
import { GetAssetResponse, MediaFileItem, ProductDetailsResultV2 } from '../../models';
import { ContentPaneComponent } from '../content-pane/content-pane.component';

export type MediaGalleryItem = MediaGalleryImage | MediaGalleryCustomImage | MediaGalleryVideo;

export interface MediaGalleryImage {
  id: string;
  type: 'image';
  previewUrl: string;
  url: string;
}

export interface MediaGalleryCustomImage {
  id: string;
  type: 'custom-image';
  filename?: string;
  uploading?: boolean;
}

export interface MediaGalleryVideo {
  id: string;
  type: 'video';
}

@Component({
  selector: 'app-media-gallery',
  templateUrl: './media-gallery.component.html',
  styleUrls: ['./media-gallery.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
    NgClass,
    ButtonModule,
    DialogModule,
    MediaCustomImageComponent,
    MediaImageComponent,
    MediaVideoComponent,
    MediaViewerComponent,
    MediaUploadComponent,
    IconComponent,
    TemplateDirective,
    NoElementsPlaceholderComponent,
    ContentPaneComponent,
  ],
})
export class MediaGalleryComponent {
  readonly asset = input.required<GetAssetResponse>();
  readonly productDetails = input<ProductDetailsResultV2 | undefined>(undefined);

  readonly customImages = computed(() => this.computeCustomImages());
  readonly productImages = computed(() => this.computeProductImages());
  readonly technicalDrawings = computed(() => this.computeTechnicalDrawings());
  readonly productVideos = computed(() => this.computeProductVideos());
  readonly writable = computed(() => this.asset().permissions?.includes('w') === true);
  readonly items = computed(
    () => (
      this.menuService.hideAll(),
      Array<MediaGalleryItem>().concat(
        this.customImages(),
        this.productImages(),
        this.technicalDrawings(),
        this.productVideos(),
      )
    ),
  );
  readonly overview = computed(() => this.items().slice(0, 12));
  readonly showViewer = model<boolean>(false);
  readonly showIndex = model(0);
  readonly showAll = signal(false);
  readonly uploadStatus = signal<UploadStatus[] | undefined>(undefined);
  private readonly forceRefresh = signal(0);

  constructor(
    private imageService: ImageService,
    private menuService: MenuService,
    assetService: AssetService,
  ) {
    effect(
      onCleanup => {
        const assetUuid = this.asset().uuid;
        const subscriptions = [
          assetService.monitorCustomImageUploads(assetUuid).subscribe({
            next: uploadStatus => {
              if (uploadStatus) {
                this.uploadStatus.set([...uploadStatus]);
              } else {
                this.uploadStatus.set(undefined);
              }
            },
          }),
          assetService.customImagesChanged.subscribe({
            next: () => this.forceRefresh.set(untracked(this.forceRefresh) + 1),
          }),
        ];
        onCleanup(() => subscriptions.forEach(x => x.unsubscribe()));
      },
      { allowSignalWrites: true },
    );
    effect(
      () => {
        if (this.items().length === 0) {
          this.showViewer.set(false);
          this.showAll.set(false);
        }
      },
      { allowSignalWrites: true },
    );
  }

  onItemSelected(item: MediaGalleryItem): void {
    const index = this.items().findIndex(x => x.id === item.id);
    if (index >= 0) {
      this.showIndex.set(index);
      this.showViewer.set(true);
    }
  }

  onShowAll(): void {
    this.showAll.set(true);
  }

  onBackToShowingAll(): void {
    this.showViewer.set(false);
  }

  onCloseAll(): void {
    this.showAll.set(false);
  }

  onViewerClosed(): void {
    this.showAll.set(false);
  }

  private computeCustomImages(): MediaGalleryCustomImage[] {
    this.forceRefresh();
    const asset = this.asset();
    const uploadStatus = this.uploadStatus() || [];
    const images = asset.images ?? [];
    return images
      .map<MediaGalleryCustomImage>(x => ({
        id: x.uuid!,
        type: 'custom-image',
        filename: x.name,
      }))
      .concat(
        uploadStatus
          // ignore failed uploads and deal with customer navigating between assets
          .filter(x => x.status !== 'error' && images.findIndex(y => y.uuid === x.uuid) < 0)
          .map<MediaGalleryCustomImage>(x => ({
            id: x.uuid,
            type: 'custom-image',
            uploading: x.status === 'pending',
          })),
      );
  }

  private computeProductImages(): MediaGalleryImage[] {
    const productDetails = this.productDetails();
    return this.computeImages(productDetails?.Product.MultiMedia.ProductImages.MediaFile);
  }

  private computeTechnicalDrawings(): MediaGalleryImage[] {
    const productDetails = this.productDetails();
    return this.computeImages(productDetails?.Product.MultiMedia.TechnicalDrawings.MediaFile);
  }

  private computeProductVideos(): MediaGalleryVideo[] {
    const productDetails = this.productDetails();
    const videos = productDetails?.Product.MultiMedia.Videos.MediaFile ?? [];
    // filter out duplicate videos - yes, this is unfortunately necessary
    const videoUrls = new Set<string>();
    for (const video of videos) {
      videoUrls.add(video.URL);
    }
    const retval: MediaGalleryVideo[] = [];
    for (const url of videoUrls) {
      retval.push({ id: url, type: 'video' });
    }
    return retval;
  }

  private computeImages(mf: MediaFileItem[] | undefined): MediaGalleryImage[] {
    const images = this.imageService.getMediaFiles(mf);
    const retval: MediaGalleryImage[] = [];
    for (const [id, image] of images) {
      const previewUrl = this.imageService.getImageUrl(image, 320);
      const url = this.imageService.getImageUrl(image, ZOOMED_IMAGE_SIZE);
      retval.push({ id, type: 'image', previewUrl, url });
    }
    return retval;
  }
}
