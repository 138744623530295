<span [class.enabled]="enabled()" (click)="showLocation()">
  @if (isShip()) {
    @if (asset().shipDetails?.imo) {
      {{ 'ship-location.show-position' | translate }}
    } @else {
      {{ 'ship-location.imo-missing' | translate }}
    }
  } @else {
    {{ address() || '-' }}
  }
</span>
