import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AssetItem, ENVIRONMENT } from '../models';
import { TreeService } from './tree.service';
import { format } from 'date-fns';
import { sanitize } from 'sanitize-filename-ts';

export interface AdditionalExportItems {
  latestSopasConfigurations?: boolean;
}

export type ExportScopes = 'ENTIRE_TREE' | 'SUBTREE' | 'SINGLE_NODE';
export type ExportScopesBackend = Exclude<ExportScopes, 'ENTIRE_TREE'>;

@Injectable({
  providedIn: 'root',
})
export class AssetExportService {
  private readonly environment = inject(ENVIRONMENT);
  private readonly apiUrl = this.environment.apiUrlEcs || this.environment.apiUrl;

  public constructor(
    private httpClient: HttpClient,
    private treeService: TreeService,
  ) {}

  public exportAsZip(
    nodeUuid: string,
    exportScope: ExportScopesBackend,
    additionalExportItems: AdditionalExportItems,
  ): Observable<{ exportData: Blob; fileName: string }> {
    const node = this.treeService.mustFindNode(nodeUuid);
    const params = new HttpParams()
      .set('exportScope', exportScope)
      .set('latestSopasConfigurations', additionalExportItems.latestSopasConfigurations ?? false);
    const response = this.httpClient.get(`${this.apiUrl}/tree/${nodeUuid}/exportAsZip`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
    return response.pipe(
      map(res => ({
        exportData: res.body!,
        fileName: this.getExportFileName(node) + '.zip',
      })),
    );
  }

  private getExportFileName(asset: AssetItem) {
    let fileName = `AssetHub-Export-${format(new Date(), "yyyy-MM-dd'T'HH-mm-ss")}`;
    let assetName = asset.name || asset.productName;
    if (assetName) {
      assetName = assetName.substring(0, 64);
      assetName = sanitize(assetName, { replacement: '_' });
      fileName = fileName + `-${assetName}`;
    }
    return fileName;
  }
}
