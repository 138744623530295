import { Component, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import { GetGeoLocationsResponse, MapMarkers } from '@assethub/shared/models';
import { GeoLocationService } from '@assethub/shared/services';
import { AppInjector } from '@assethub/shared/utils/app-injector';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { map } from 'rxjs';
import { AssetHeremapComponent } from '../asset-heremap/asset-heremap.component';
import { CalloutComponent } from '../callout/callout.component';

interface DialogData {
  assetUuid: string;
}

@Component({
  selector: 'app-asset-location-map',
  standalone: true,
  imports: [TranslateModule, AssetHeremapComponent, CalloutComponent, ProgressSpinnerModule],
  templateUrl: './asset-location-map.component.html',
  styleUrls: ['./asset-location-map.component.scss'],
})
export class AssetLocationMapComponent {
  private assetUuid = signal<string>('');
  readonly markers = signal<MapMarkers>([]);
  readonly state = signal<'loading' | 'error' | 'empty' | 'data'>('loading');

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig<DialogData>,
    private translateService: TranslateService,
    private geoLocationService: GeoLocationService,
    private router: Router,
  ) {
    this.config.header = this.translateService.instant('geoLocation.title');
    this.config.focusOnShow = false;
    this.config.style = {
      'max-width': '60em',
      'min-height': '80%',
      'max-height': '80%',
    };

    const data = this.config.data;
    if (data) {
      this.assetUuid.set(data.assetUuid);
    } else {
      this.state.set('error');
    }

    effect(
      () => {
        const assetUuid = this.assetUuid();
        if (!assetUuid) {
          this.state.set('error');
          return;
        }
        this.state.set('loading');
        this.geoLocationService
          .getGeoLocations(assetUuid)
          .pipe(map(locations => this.mapGeoLocationsToMarkers(locations)))
          .subscribe({
            next: markers => {
              this.markers.set(markers);
              this.state.set(this.markers().length === 0 ? 'empty' : 'data');
            },
            error: () => {
              this.state.set('error');
            },
          });
      },
      { allowSignalWrites: true },
    );
  }

  static open(assetUuid: string) {
    const data: DialogData = { assetUuid };
    return AppInjector.get(DialogService).open(AssetLocationMapComponent, { data });
  }

  navigateToDetails(uuid: string) {
    this.ref.close();
    this.router.navigate(['/asset', uuid]);
  }

  navigateToMap(uuid: string) {
    this.ref.close();
    this.router.navigate(['/asset', uuid, 'map']);
  }

  private mapGeoLocationsToMarkers(locations: GetGeoLocationsResponse): MapMarkers {
    if (locations.length === 0) {
      return [];
    }
    return locations.flatMap(location => {
      if (!location.latitude || !location.longitude) {
        return [];
      }
      return {
        latitude: location.latitude,
        longitude: location.longitude,
        assetUuid: location.assetUuid,
        typeId: location.typeId,
        name: location.customName,
        profilePicture: location.profilePicture,
        productPictureUrl: location.productPictureUrl,
        productName: location.productName,
      };
    });
  }
}
