import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Logger } from '@assethub/shared/utils';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, mergeMap, tap } from 'rxjs/operators';
import { ENVIRONMENT, NotificationMessage } from '../models';
import { retryBackoff } from '../rxjs/backoff';
import { FrontendInstanceService } from './frontend-instance.service';
import { WebSocketService } from './web-socket.service';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  public notification$: Observable<NotificationMessage>;

  private subject = new Subject<NotificationMessage>();
  private wsSubscription?: Subscription;
  private logger = new Logger(this.constructor.name);

  private apiUrl = inject(ENVIRONMENT).apiUrl;
  private websocketUrl = inject(ENVIRONMENT).websocketUrl;

  constructor(
    private httpClient: HttpClient,
    private webSocketService: WebSocketService,
    private frontendInstanceService: FrontendInstanceService,
    private messageService: MessageService,
  ) {
    this.notification$ = this.subject.asObservable();
  }

  public connect() {
    if (this.wsSubscription) {
      this.logger.debug('Websocket already connected');
      return;
    }

    let connectedAt: Date;
    this.wsSubscription = this.httpClient
      .get<{ token: string }>(this.apiUrl + '/websocket')
      .pipe(
        mergeMap(result => {
          this.logger.debug('Received websocket token, connecting ...');
          connectedAt = new Date();
          return this.webSocketService.connect<NotificationMessage>(
            this.websocketUrl + '?token=' + result.token,
          );
        }),
        retryBackoff({
          initialInterval: 1000, // 1 sec
          maxRetries: 5,
          resetOnSuccess: true,
        }),
        tap(message => this.logger.debug('Websocket message received:', message)),
        filter(message => this.acceptMessage(message)),
      )
      .subscribe({
        next: message => {
          this.subject.next(message);
        },
        error: err => {
          this.logger.warn('Websocket error: ', err);
          this.messageService.add({
            severity: 'error',
            summary: 'toasts.websocket.connection-failed',
            sticky: true,
          });
          this.releaseWebsocket();
        },
        complete: () => {
          this.logger.info(
            'Websocket was closed - attempting to reconnect from scratch (connected for %s min)',
            Number((new Date().getTime() - connectedAt.getTime()) / 60000).toFixed(1),
          );
          this.releaseWebsocket();
          setTimeout(() => {
            // Delay reconnect to avoid duplicate websocket requests when reloading the app
            this.connect();
          }, 100);
        },
      });
  }

  private acceptMessage(message: NotificationMessage): boolean {
    if (this.frontendInstanceService.isSameInstance(message.originId)) {
      this.logger.debug('Discarding message from same origin.');
      return false;
    }

    this.logger.debug('Locally dispatching message');
    return true;
  }

  private releaseWebsocket() {
    this.wsSubscription?.unsubscribe();
    this.wsSubscription = undefined;
  }
}

export class MockNotificationService {
  public notification$: Observable<NotificationMessage>;
  private subject = new Subject<NotificationMessage>();

  constructor() {
    this.notification$ = this.subject.asObservable();
  }

  public connect() {
    // noop
  }

  public emit(notification: NotificationMessage) {
    this.subject.next(notification);
  }
}
