// Disabled variable naming with underscores due to external backend response
/* eslint-disable @typescript-eslint/naming-convention */
export interface ProductListItem {
  Name: string;
  PartNumber?: string;
  ImageURL: string;
  WebsiteURL?: string;
  ProductFamilyGroup?: string;
  ProductFamily?: string;
  info?: string;
}

export interface ProductsQueryResult {
  Product: ProductListItem[];
}

export interface ProductsSearchResult {
  results: {
    Product: ProductListItem[];
  };
  currentPage: number;
  resultsPerPage: number;
  total: number;
  pageCount: number;
}

export interface MediaFileItem {
  ID: string;
  URL: string;
  MimeType: string;
  FileName?: string;
  MediaFormat: string;
  MediaContainer?: string;
  Description?: string;
}

export interface DeclarationOfConformityItem {
  ID: string;
  URL: string;
  MimeType: string;
  MediaFormat: string;
  Description: string;
  Preview: {
    ID: string;
    URL: string;
    MimeType: string;
    MediaFormat: string;
  };
  PartNumber: string;
}

export interface SoftwareDownloadItem {
  ID: string;
  URL: string;
  MimeType: string;
  Description: string;
  Category: string;
  Version: string;
  Type: string;
}

export interface LiteratureItem {
  ID: string;
  URL: string;
  MimeType: string;
  Description: string;
  PublicationType: string;
  Language: string;
  Preview: {
    ID: string;
    URL: string;
    MimeType: string;
    MediaFormat: string;
  };
  ReleaseDate: string;
}

export interface ProductDetails {
  Name: string;
  PartNumber: string;
  Videos: {
    MediaFile?: MediaFileItem[];
  };
  Images: {
    MediaFile?: MediaFileItem[];
  };
  TechnicalDrawings: {
    MediaFile?: MediaFileItem[];
  };
  OtherDownloads: object;
  Software: {
    SoftwareDownload?: SoftwareDownloadItem[];
  };
  ConformityDeclaration: {
    DeclarationOfConformity?: DeclarationOfConformityItem[];
  };
  CAD: {
    EDB?: {
      MediaFile?: MediaFileItem[];
    };
    CADENAS?: MediaFileItem;
  };
  FactSheet: {
    ID?: string;
    URL?: string;
    MimeType?: string;
    MediaFormat?: string;
  };
  Certificates: {
    MediaFile?: MediaFileItem[];
  };
  Literature: {
    Literature?: LiteratureItem[];
  };
}

export interface RelationItem {
  Quantity: string;
  Type:
    | 'ACCESSORIES'
    | 'OTHER_ACCESSORIES'
    | 'SERVICE'
    | 'OTHER_SERVICE'
    | 'SPAREPART'
    | 'SUCCESSOR';
  Target: {
    PartNumber: string;
    Name: string;
    ID: string;
  };
}

export interface UnitOfMeasure {
  ISO: string; // ex: KGM
  Name: string; // ex: Kilograms
  Code: string; // ex: kg
}

export interface ValueAndUnit {
  Value: string;
  UnitOfMeasure: UnitOfMeasure;
}

export interface Country {
  Name: string;
  ISOCode: string;
}

export interface SubsidiaryData {
  PrivateLabelCustomerNumbers: any;
  Subsidiary: {
    Country: Country;
    AdditionalSupportedCountries: any;
    Name: string; // plaintext name of ssu
    Code: string; // ssu-code
  };
  WebSellable: 'true' | 'false';
  ProductStatusDistributionChainSpecific: string;
  CommodityCode: string;
  ProductStatusCrossDistributionChain: string;
  Public: 'true' | 'false';
}

export type CADData = {
  EDB?: {
    MediaFile?: MediaFileItem[];
  };
  CADENAS?: MediaFileItem;
};

export interface ProductDetailsV2 {
  MetaData: {
    ProductType: string;
    PartNumber: string;
    Name: string;
    ID: string;
  };
  MultiMedia: {
    Videos: {
      MediaFile?: MediaFileItem[];
    };
    TechnicalDrawings: {
      MediaFile?: MediaFileItem[];
    };
    OtherDownloads: Record<string, never>;
    Software: {
      SoftwareDownload?: SoftwareDownloadItem[];
    };
    ConformityDeclaration: {
      DeclarationOfConformity?: DeclarationOfConformityItem[];
    };
    CAD: CADData;
    FactSheet: {
      ID?: string;
      URL?: string;
      MimeType?: string;
      MediaFormat?: string;
    };
    Certificates: {
      MediaFile?: MediaFileItem[];
    };
    Literature: {
      Literature?: LiteratureItem[];
    };
    ProductImages: {
      MediaFile?: MediaFileItem[];
    };
  };
  Relations: {
    Relation?: RelationItem[];
  };
  ERPData: {
    Logistics?: {
      ProductPhase: string;
      PhaseOut?: {
        PhasedOut: string; // ISO 8601, ex: 2018-02-08T00:00:00.000+01:00
        ToBePhasedOut: string;
        InPhaseOut: string;
      };
      UnitOfMeasure: UnitOfMeasure;
      Weight: {
        UnitFreight: ValueAndUnit;
        NetUnitWeight: ValueAndUnit;
        UnitWeight: ValueAndUnit;
      };
      ServiceLevelPercentage: string;
      GlobalAvailability: string;
      GlobalItemClassCode: string;
      Dimensions: {
        Volume: ValueAndUnit;
        Measurements: string;
      };
    };
    SubsidiarySpecific?: {
      SubsidiaryData?: SubsidiaryData[];
    };
  };
  AAS?: boolean;
}

export interface ProductDetailsResultV2 {
  Product: ProductDetailsV2;
}

export interface ProductDetailsResult {
  Product: ProductDetails;
}
