import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { GetAssetResponse, SystemState } from '@assethub/shared/models';
import { AssetStateService } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'app-system-state',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './system-state.component.html',
  styleUrls: ['./system-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SystemStateComponent {
  private readonly assetStateService = inject(AssetStateService);

  readonly asset = input.required<GetAssetResponse>();
  readonly systemState: Signal<SystemState> = toSignal(
    toObservable(this.asset).pipe(
      switchMap(asset => this.assetStateService.fetchConnectionState(asset)),
      map(inventoryOpData => inventoryOpData.systemState),
    ),
    { initialValue: SystemState.VOID },
  );
  readonly isErrorState = computed(() => this.systemState() === SystemState.ERROR);
}
