import { Component, computed, signal } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInjector } from '@assethub/shared/utils/app-injector';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CalloutComponent } from '../callout/callout.component';

interface DialogData {
  imo: string;
}

@Component({
  selector: 'app-ship-location-map',
  standalone: true,
  imports: [TranslateModule, CalloutComponent],
  templateUrl: './ship-location-map.component.html',
  styleUrls: ['./ship-location-map.component.scss'],
})
export class ShipLocationMapComponent {
  readonly imo = signal<string>('');
  readonly url = computed(() => this.computeUrl());

  constructor(
    private config: DynamicDialogConfig<DialogData>,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
  ) {
    this.config.header = this.translateService.instant('ship-location.title');
    this.config.focusOnShow = false;
    this.config.style = {
      'max-width': '60em',
      'min-height': '80%',
      'max-height': '80%',
    };

    const data = this.config.data;
    if (data) {
      this.imo.set(data.imo);
    }
  }

  static open(imo: string) {
    const data: DialogData = { imo };
    return AppInjector.get(DialogService).open(ShipLocationMapComponent, { data });
  }

  private computeUrl() {
    const imo = this.imo();
    if (imo && /^\d+$/.test(imo)) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.vesselfinder.com/aismap?imo=${imo}&track=true`,
      );
    }
    return undefined;
  }
}
