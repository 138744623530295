@switch (state()) {
  @case ('loading') {
    <div class="spinner-wrapper">
      <p-progressSpinner></p-progressSpinner>
    </div>
  }
  @case ('error') {
    <app-callout type="error">{{ 'geoLocation.message.error' | translate }}</app-callout>
  }
  @case ('empty') {
    <app-callout type="info">{{ 'geoLocation.message.no-data' | translate }}</app-callout>
  }
  @case ('data') {
    <app-asset-heremap
      [markers]="markers()"
      (detailsClicked)="navigateToDetails($event)"
      (mapClicked)="navigateToMap($event)"
    ></app-asset-heremap>
  }
}
