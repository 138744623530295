import { PinMetadata } from '@assethub/shared/components';

export type GetGeoLocationsResponse = {
  inherited: boolean;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  country: string;
  latitude: number | null;
  longitude: number | null;
  depth: number;
  assetUuid: string;
  typeId: number;
  customName?: string;
  profilePicture?: string;
  productPictureUrl?: string;
  productName?: string;
}[];

export interface GpsPosition {
  latitude: number | null;
  longitude: number | null;
}

export interface MapMarkerElement extends PinMetadata {
  longitude: number;
  latitude: number;
}

export type MapMarkers = MapMarkerElement[];
