import { NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'app-content-pane',
  templateUrl: './content-pane.component.html',
  styleUrls: ['./content-pane.component.scss'],
  standalone: true,
  imports: [NgStyle],
})
export class ContentPaneComponent {
  title = input.required<string>();
  maxHeight = input('35em');
}
