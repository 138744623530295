<div class="content-pane-component">
  <div class="header">
    <h5 class="title">{{ title() }}</h5>
    <div class="action-bar">
      <ng-content select="[action-bar]"></ng-content>
    </div>
  </div>
  <div class="content" [ngStyle]="{ 'max-height': maxHeight() }">
    <ng-content></ng-content>
  </div>
</div>
