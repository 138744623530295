import { inject, Injectable } from '@angular/core';
import { IccState } from '@liveconnect/shared/models';
import { DeviceMonitorService, IccService } from '@liveconnect/shared/services';
import { map, Observable, of, share, switchMap, timer } from 'rxjs';
import {
  AssetConnectionState,
  AssetInventoryDetailsResponse,
  GetAssetResponse,
  SystemState,
} from '../models';
import { AssetService } from './asset.service';

type AssetStateDetails = Required<AssetInventoryDetailsResponse>;

@Injectable({ providedIn: 'root' })
export class AssetStateService {
  private assetService = inject(AssetService);
  private deviceMonitorService = inject(DeviceMonitorService);
  private iccService = inject(IccService);
  private cycleStateUpdateSeconds = 15;

  private inventoryDetailsLoader: Record<string, Observable<AssetStateDetails>> = {};

  hasInventorySync(asset?: GetAssetResponse): boolean {
    return asset?.inventoryDetails?.synced !== undefined;
  }

  fetchConnectionState(asset?: GetAssetResponse): Observable<AssetStateDetails> {
    if (asset?.inventoryDetails?.synced === undefined) {
      return of({ connectionState: AssetConnectionState.VOID, systemState: SystemState.VOID });
    }

    if (this.deviceMonitorService.canMonitorDevices()) {
      return this.fetchFromIccService(asset.uuid);
    }

    return this.fetchFromInventoryData(asset.uuid);
  }

  private fetchFromIccService(uuid: string): Observable<AssetStateDetails> {
    return this.deviceMonitorService.getIccState(uuid).pipe(
      switchMap(iccState => {
        if (iccState === IccState.VOID) {
          return of({ connectionState: AssetConnectionState.VOID, systemState: SystemState.VOID });
        }
        return this.iccService.getCurrentConnection(uuid).pipe(
          map(connection => {
            const connectionState = connection.connected
              ? AssetConnectionState.ONLINE
              : AssetConnectionState.OFFLINE;
            return { connectionState, systemState: SystemState.VOID };
          }),
        );
      }),
    );
  }

  private fetchFromInventoryData(uuid: string): Observable<AssetStateDetails> {
    if (this.inventoryDetailsLoader[uuid] === undefined) {
      this.inventoryDetailsLoader[uuid] = (
        this.cycleStateUpdateSeconds > 0 ? timer(0, this.cycleStateUpdateSeconds * 1000) : of(0)
      ).pipe(
        switchMap(() =>
          this.assetService.fetchInventoryAssetDetails(uuid).pipe(
            map(res => ({
              connectionState: res.connectionState || AssetConnectionState.VOID,
              systemState: res.systemState || SystemState.VOID,
            })),
          ),
        ),
        share(),
      );
    }

    return this.inventoryDetailsLoader[uuid];
  }
}
