export interface OperatingData {
  name: string;
  value: string | number;
  updatedAt: Date;
}

export interface OperatingDataResponse {
  name: string;
  value: string | number;
  updatedAt: string;
}

export interface AssetInventoryDetailsResponse {
  connectionState?: AssetConnectionState;
  systemState?: SystemState;
}

export enum AssetConnectionState {
  VOID = 'VOID',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum SystemState {
  VOID = 'VOID', // Value unset or connection timeout
  READY = 'READY', // Only statusCode "110"
  ERROR = 'ERROR', // All other status codes
}

export const DEVICE_READY_SYSTEM_READY = '110';
