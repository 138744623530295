import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { AssetConnectionState, GetAssetResponse } from '@assethub/shared/models';
import { AssetStateService } from '@assethub/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { map, switchMap } from 'rxjs';

@Component({
  selector: 'app-asset-connection-state',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './connection-state.component.html',
  styleUrl: './connection-state.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionStateComponent {
  private readonly assetStateService = inject(AssetStateService);

  readonly asset = input.required<GetAssetResponse>();
  readonly connectionState: Signal<AssetConnectionState> = toSignal(
    toObservable(this.asset).pipe(
      switchMap(asset => this.assetStateService.fetchConnectionState(asset)),
      map(inventoryOpData => inventoryOpData.connectionState),
    ),
    { initialValue: AssetConnectionState.VOID },
  );
  readonly isOffline = computed(() => this.connectionState() === AssetConnectionState.OFFLINE);
}
