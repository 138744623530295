import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  OnDestroy,
} from '@angular/core';
import { AssetTypeMapping, GetAssetResponse } from '@assethub/shared/models';
import { CountryIsoCodeService } from '@assethub/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssetLocationMapComponent } from '../asset-location-map/asset-location-map.component';
import { ShipLocationMapComponent } from '../ship-location-map/ship-location-map.component';

@Component({
  selector: 'app-asset-location-label',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './asset-location-label.component.html',
  styleUrls: ['./asset-location-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetLocationLabelComponent implements OnDestroy {
  readonly asset = input.required<GetAssetResponse>();
  readonly enabled = computed(() => this.computeEnabled());
  readonly address = computed(() => this.computeAddress());
  readonly isShip = computed(() => this.asset().typeId === AssetTypeMapping.SHIP);

  private dialogRef?: DynamicDialogRef;

  constructor(
    private translateService: TranslateService,
    private countryService: CountryIsoCodeService,
  ) {
    effect(() => {
      this.asset();
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.closeDialog();
  }

  private closeDialog() {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
    }
  }

  private computeEnabled() {
    const asset = this.asset();
    return asset.typeId !== AssetTypeMapping.SHIP || !!asset.shipDetails?.imo;
  }

  private computeAddress() {
    const asset = this.asset();
    const location = asset?.location;

    if (asset.typeId === AssetTypeMapping.SHIP || !location) {
      return '';
    }

    const address = [location.address1, location.address2, location.zipCode + ' ' + location.city];

    if (location.country) {
      const iso3Code =
        location.country.length === 2
          ? this.countryService.getCountryISO3(location.country)
          : location.country;
      const countries: { code: string; label: string }[] =
        this.translateService.instant('countries');
      address.push(countries.find(c => c.code === iso3Code)?.label || '');
    }

    return address
      .map(x => x.trim())
      .filter(x => x)
      .join(', ');
  }

  showLocation() {
    const asset = this.asset();
    if (asset.typeId === AssetTypeMapping.SHIP) {
      this.dialogRef = ShipLocationMapComponent.open(asset.shipDetails?.imo || '');
    } else {
      this.dialogRef = AssetLocationMapComponent.open(asset.uuid);
    }
    this.dialogRef.onClose.subscribe(() => {
      this.dialogRef = undefined;
    });
  }
}
