<p-multiSelect
  (resized)="onResized($event)"
  optionLabel="displayLabel"
  dataKey="value"
  [showHeader]="true"
  [showToggleAll]="false"
  [options]="options"
  [(ngModel)]="selectedOptions"
  (onChange)="update()"
  [autofocusFilter]="false"
  [disabled]="disabled"
  [filter]="displayFilterInput"
  appendTo="body"
  [panelStyle]="panelStyle"
  [virtualScroll]="true"
  [virtualScrollItemSize]="46"
  [virtualScrollOptions]="{ style: { 'overflow-x': 'hidden', 'height': '200px' } }"
>
  <ng-template pTemplate="selectedItems">
    @if (selectedOptions.length <= maxSelectedLabels) {
      @for (item of selectedOptions; track item.value; let last = $last) {
        {{ !translateItems && !item.translate ? item.displayLabel : (item.displayLabel | translate)
        }}{{ last ? '' : ', ' }}
      } @empty {
        {{ placeholder }}
      }
    } @else {
      {{ selectedOptions.length }} {{ 'multiSelect.itemsSelected' | translate }}
    }
  </ng-template>

  <ng-template let-item pTemplate="item">
    @if (item.metas?.assetIcon) {
      <app-asset-icon [asset]="item.metas.assetIcon"></app-asset-icon>
    } @else if (item.icon) {
      <app-icon [shape]="item.icon" [color]="item.iconColor"></app-icon>
    }
    <span>{{
      !translateItems && !item.translate ? item.displayLabel : (item.displayLabel | translate)
    }}</span>
  </ng-template>

  <ng-template pTemplate="footer">
    <div class="multiselect-footer">
      <hr />
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-times"
        label="{{ 'multiselect-control.footer.remove-selected-label' | translate }}"
        class="ui-button-bare"
        [disabled]="!enableUnselectAll"
        (click)="unSelectAll($event)"
      ></button>
      <button
        pButton
        pRipple
        type="button"
        icon="pi pi-check"
        label="{{ 'multiselect-control.footer.select-all-label' | translate }}"
        class="ui-button-bare"
        [disabled]="!enableSelectAll"
        (click)="selectAll()"
      ></button>
    </div>
  </ng-template>
</p-multiSelect>
